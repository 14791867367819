import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from "./getters";

const state = {
    beneficiario: null,
    imagemCartao: null
};

const namespaced = true;

export const consultaCidadao = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};
