<template>
  <div id="app" class="">
    <LayoutPrincipal/>
    <ModalLoading v-if="loading"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import "tailwindcss/tailwind.css";
import LayoutPrincipal from '@/components/layout/LayoutPrincipal'
import ModalLoading from '@/components/modals/ModalLoading'


export default {
  name: 'app',
  components: {
    LayoutPrincipal,
    ModalLoading
  },
  computed: {
    ...mapGetters('adm', ['loading']),

  },
  methods: {}

}

</script>

<style>
@import './assets/css/style.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
