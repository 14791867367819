<template>
  <div class="flex justify-center items-center">
    <ul class="w-full flex flex-wrap justify-center md:content-end pagination bg-white text-gray-700 py-2 shadow-sm rounded">
      <li class=" pagination-item" v-if="showFirstLast">
        <button
          type="button"
          @click.prevent="onClickFirstPage"
          class="block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full"
          :class="{ 'cursor-not-allowed text-gray-500': isInFirstPage }"
          :disabled="isInFirstPage"
          href="#"
          role="button"
          rel="prev"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"
            />
          </svg>
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
          aria-label="Go to previous page"
          class="block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full"
          :class="{ 'cursor-not-allowed text-gray-500': isInFirstPage }"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
            />
          </svg>
        </button>
      </li>
  
      <li v-for="page in pages" class="pagination-item" :key="page.name">
        <span
          class="block shadow-md text-xs text-gray-100 font-bold bg-principal px-4 pt-2 py-1 h-full rounded mr-1"
          v-if="isPageActive(page.name)"
          >{{ page.name }}</span
        >
        <a
          class="block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 py-1 h-full"
          href="#"
          v-else-if="page.name == 'hasMorePage'"
          @click="onClickNextPage"
          role="button"
        >
          ...
        </a>
        <a
          class="block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 py-1 h-full"
          href="#"
          v-else-if="page.name == 'hasLessPage'"
          @click="onClickPreviousPage"
          role="button"
        >
          ...
        </a>
        <a
          class="block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 pt-2 py-1 h-full "
          href="#"
          v-else
          @click.prevent="onClickPage(page.name - 1)"
          role="button"
          >{{ page.name }}</a
        >
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
          aria-label="Go to next page"
          class="block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full"
          :class="{ 'cursor-not-allowed text-gray-500': isInLastPage }"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </button>
      </li>

      <li class="pagination-item" v-if="showFirstLast">
        <button
          type="button"
          @click.prevent="onClickLastPage"
          :disabled="isInLastPage"
          aria-label="Go to next page"
          class="block text-xs hover:text-white hover:bg-gray-500 roundeds mr-1 border border-gray-light px-3 py-1 h-full"
          :class="{ 'cursor-not-allowed text-gray-500': isInLastPage }"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"
            />
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AppPagination",
  data() {
    return {};
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },

    totalPages: {
      type: Number,
      required: true
    },

    total: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    hasMorePages: {
      type: Boolean,
      required: true
    },
    showFirstLast: {
      type: Boolean,
      required: false
    },
    limitButtons: {
      type: Boolean,
      default: true,
      required: false
    },
    isPageable: {
      type: Boolean,
      default: true,
      require: false
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 0) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        if (
          this.maxVisibleButtons <= this.totalPages &&
          this.maxVisibleButtons > 1
        ) {
          return this.totalPages - this.maxVisibleButtons + 1;
        }
      }
      if (
        this.maxVisibleButtons < this.totalPages &&
        this.maxVisibleButtons != 1 &&
        this.limitButtons
      ) {
        return this.currentPage;
      }

      if (this.maxVisibleButtons == 1) {
        return this.currentPage + 1;
      }
      return 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      let lastIndex = this.endPage;
      let firstIndex = this.startPage;
      let maxPage = this.endPage;
      let minPage = this.startPage;

      if (this.limitButtons) {
        if (
          this.maxVisibleButtons <= this.totalPages &&
          this.maxVisibleButtons > 1
        ) {
          if (this.endPage - this.startPage < this.maxVisibleButtons - 1) {
            minPage = this.totalPages - this.maxVisibleButtons + 1;
          }
        }

        for (let i = minPage; i <= maxPage; i += 1) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage
          });
        }

        if (
          this.maxVisibleButtons < this.totalPages &&
          this.maxVisibleButtons > 1
        ) {
          if (lastIndex < this.totalPages) {
            range.push({
              name: "hasMorePage",
              isDisabled: true
            });
            range.push({
              name: this.totalPages,
              isDisabled: this.totalPages === this.currentPage
            });
          }

          if (firstIndex > 1) {
            range.unshift({
              name: "hasLessPage",
              isDisabled: true
            });
            range.unshift({
              name: 1,
              isDisabled: 1 === this.currentPage
            });
          }
        }
      } else {
        for (let i = 1; i <= this.totalPages; i += 1) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage
          });
        }
      }
      return range;
    },
    isInFirstPage() {
      if (this.isPageable) {
        return this.currentPage === 0;
      }
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.isPageable) {
        return this.currentPage === this.totalPages - 1;
      }
      return this.currentPage === this.totalPages;
    }
  },
  methods: {
    onClickFirstPage() {
      if (this.isPageable) {
        this.$emit("pagechanged", 0);
      } else {
        this.$emit("pagechanged", 1);
      }
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      if (this.isPageable) {
        this.$emit("pagechanged", page);
      } else {
        this.$emit("pagechanged", page + 1);
      }
     
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      if (this.isPageable) {
        this.$emit("pagechanged", this.totalPages - 1);
      } else {
        this.$emit("pagechanged", this.totalPages);
      }
    },
    isPageActive(page) {
      if (this.isPageable) {
        return this.currentPage === page - 1;
      }
      return this.currentPage === page;
    },
    onClickLessPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickMorePage(page) {
      this.$emit("pagechanged", page);
    }
  }
};
</script>
<style scoped>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}
</style>
