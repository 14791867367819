var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-12 bg-white" }, [
    _c("div", { staticClass: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" }, [
      _c(
        "h2",
        { staticClass: "text-md font-extrabold tracking-wide uppercase my-2" },
        [_vm._v(" Consulta Cidadão ")]
      ),
      _c("div", { staticClass: "lg:text-center justify-center flex" }, [
        _c(
          "div",
          { staticClass: "flex flex-col md:flex-row w-full md:space-x-4" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filtro,
                  expression: "filtro"
                }
              ],
              staticClass: "p-2 block w-full md:w-2/5 border border-gray-200",
              attrs: {
                type: "text",
                name: "cpfNome",
                id: "cpfNome",
                placeholder: "Digite CPF ou Nome"
              },
              domProps: { value: _vm.filtro },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.filtro = $event.target.value
                  },
                  function($event) {
                    return _vm.filtrarConsulta()
                  }
                ]
              }
            }),
            _c("div", { staticClass: "w-full md:w-2/5" }, [
              _c("div", { staticClass: "relative w-full" }, [
                _c(
                  "select",
                  {
                    staticClass:
                      "\n                  block\n                  appearance-none\n                  w-full\n                  border border-gray-200\n                  text-gray-700\n                  py-3\n                  px-4\n                  pr-8\n                  rounded\n                  leading-tight\n                  focus:outline-none focus:bg-white focus:border-gray-500\n                ",
                    class: _vm.isCPF ? "bg-gray-100 cursor-not-allowed" : "",
                    attrs: { id: "grid-state", disabled: _vm.isCPF }
                  },
                  [
                    _c("option", [_vm._v("Todos")]),
                    _vm._l(_vm.municipios, function(item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item } },
                        [_vm._v(_vm._s(item.municipio))]
                      )
                    })
                  ],
                  2
                ),
                _c("div", {
                  staticClass:
                    "\n                  pointer-events-none\n                  absolute\n                  inset-y-0\n                  right-0\n                  flex\n                  items-center\n                  px-2\n                  text-gray-700\n                "
                })
              ])
            ]),
            _c(
              "button",
              {
                staticClass:
                  "\n            bg-green-600\n            px-8\n            shadow-sm\n            text-sm\n            leading-4\n            font-medium\n            text-white\n            mt-2\n            md:mt-0\n            py-3\n          ",
                attrs: { type: "button" },
                on: { click: _vm.consultaBeneficiario }
              },
              [_vm._v(" Consultar ")]
            )
          ]
        )
      ])
    ]),
    _vm.listaBeneficiario &&
    _vm.listaBeneficiario.length > 0 &&
    !_vm.showDetalhes
      ? _c("div", { staticClass: "pt-6 pb-2 mx-8" }, [
          _c("span", { staticClass: "block" }, [
            _vm._v("Resultado da Consulta:")
          ]),
          _c("span", { staticClass: "flex justify-start px-4" }, [
            _vm._v(
              "Foram encontrados " +
                _vm._s(_vm.pageable.total) +
                " com os termos"
            )
          ])
        ])
      : _vm._e(),
    _vm.listaBeneficiario && _vm.listaBeneficiario.length > 0
      ? _c("div", { staticClass: "min-h-screen", attrs: { id: "resultado" } }, [
          !_vm.showDetalhes
            ? _c(
                "div",
                { staticClass: "mx-8 px-2 py-4 bg-white" },
                [
                  _c("div", { staticClass: "relative py-4 pb-8" }, [
                    _c("table", { staticClass: "border-collapse w-full" }, [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.listaBeneficiario, function(item) {
                          return _c(
                            "tr",
                            {
                              key: item.id,
                              staticClass:
                                "\n                bg-white\n                shadow-lg\n                sm:shadow-none\n                mb-6\n                md:mb-0\n                flex flex-row flex-wrap\n                hover:bg-gray-100\n                sm:flex-no-wrap\n                border-b border-l-2 border-r-2\n                hover:border-gray-600\n              "
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "\n                  pl-4\n                  py-8\n                  pb-2\n                  md:pb-0\n                  text-left\n                  relative\n                  w-2/4\n                  md:w-1/5 md:px-4\n                  border-t border-l-0\n                  md:pt-4\n                  border-gray-300\n                "
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    font-bold\n                    text-xs text-gray-700\n                    uppercase\n                    md:hidden\n                    absolute\n                    top-0\n                    inset-x-0\n                    p-1\n                    bg-gray-200\n                    py-2\n                    pl-2\n                  "
                                    },
                                    [_vm._v(" CPF ")]
                                  ),
                                  _vm._v(" " + _vm._s(item.cpf) + " ")
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "\n                  pl-4\n                  pt-8\n                  pb-2\n                  md:pb-0\n                  text-left text-gray-800\n                  relative\n                  w-2/4\n                  md:w-1/5 md:pt-4\n                  border-t\n                  md:border-l md:border-r\n                  border-l-0\n                  sm:border-r-0\n                  border-gray-300\n                "
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    font-bold\n                    text-xs text-gray-700\n                    uppercase\n                    md:hidden\n                    absolute\n                    top-0\n                    inset-x-0\n                    p-1\n                    bg-gray-200\n                    py-2\n                    pl-2\n                  "
                                    },
                                    [_vm._v(" Nome Beneficiário ")]
                                  ),
                                  _vm._v(" " + _vm._s(item.nome) + " ")
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "\n                  pl-4\n                  sm:pl-0\n                  pt-8\n                  pb-2\n                  md:pb-0 md:px-4\n                  text-left text-gray-800\n                  md:pt-4\n                  relative\n                  w-2/4\n                  md:w-1/5\n                  border-t\n                  md:border-r\n                  border-l-0 border-gray-300\n                "
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    font-bold\n                    text-xs text-gray-700\n                    uppercase\n                    md:hidden\n                    absolute\n                    top-0\n                    inset-x-0\n                    p-1\n                    bg-gray-200\n                    py-2\n                    pl-2\n                  "
                                    },
                                    [_vm._v(" Data Nascimento ")]
                                  ),
                                  _vm._v(
                                    " " + _vm._s(item.dataNascimento) + " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "\n                  pl-4\n                  pt-8\n                  pb-2\n                  md:pb-0 md:px-4\n                  text-left text-gray-800\n                  md:pt-4\n                  relative\n                  w-2/4\n                  md:w-1/5\n                  border-t\n                  md:border-r\n                  border-l-0 border-r-0 border-gray-300\n                "
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    font-bold\n                    text-xs text-gray-700\n                    uppercase\n                    md:hidden\n                    absolute\n                    top-0\n                    inset-x-0\n                    p-1\n                    bg-gray-200\n                    py-2\n                    pl-2\n                  "
                                    },
                                    [_vm._v(" Município ")]
                                  ),
                                  _vm._v(" " + _vm._s(item.municipio) + " ")
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "\n                  pl-4\n                  sm:pl-0\n                  pt-8\n                  pb-2\n                  md:pb-0\n                  sm:pr-4\n                  text-center\n                  md:px-4 md:text-center md:pt-2\n                  text-gray-800\n                  relative\n                  w-full\n                  md:w-1/5\n                  border-t border-l\n                  sm:border-l-0\n                  border-gray-300\n                "
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    font-bold\n                    text-xs text-gray-700\n                    uppercase\n                    md:hidden\n                    absolute\n                    top-0\n                    inset-x-0\n                    p-1\n                    bg-gray-200\n                    py-2\n                    pl-2\n                  "
                                    },
                                    [_vm._v(" Detalhes ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "px-8 bg-blue-500 md:px-4 rounded mt-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.exibirDetalhes(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "text-gray-100",
                                          staticStyle: {
                                            width: "32px",
                                            height: "32px"
                                          },
                                          attrs: { viewBox: "0 0 24 24" }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              fill: "currentColor",
                                              d:
                                                "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("app-pagination", {
                    staticClass: "w-full flex'",
                    attrs: {
                      "total-pages": _vm.pageable.totalPages,
                      total: _vm.pageable.total,
                      "per-page": _vm.pageable.perPage,
                      "current-page": _vm.pageable.currentPage,
                      "has-more-pages": _vm.pageable.hasMorePages
                    },
                    on: { pagechanged: _vm.showMore }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showDetalhes && _vm.beneficiario
            ? _c(
                "div",
                [
                  _c("detalhes-cidadao", {
                    attrs: { cidadao: _vm.beneficiario },
                    on: {
                      onCloseDetalhes: function($event) {
                        _vm.showDetalhes = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.listaBeneficiario && _vm.listaBeneficiario.length == 0
      ? _c("div", { staticClass: "py-8" }, [
          _vm._v(" Não foram encontrados dados com os termos. ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "thead",
      { staticClass: "md:block hidden absolute sm:relative bg-white" },
      [
        _c("tr", { staticClass: "border border-gray-200 flex" }, [
          _c(
            "th",
            {
              staticClass:
                "text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
            },
            [_vm._v(" CPF ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
            },
            [_vm._v(" Nome Beneficiário ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
            },
            [_vm._v(" Data Nascimento ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
            },
            [_vm._v(" Município ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "\n                  text-center text-gray-700\n                  capitalize\n                  px-4\n                  py-2\n                  md:w-1/5\n                "
            },
            [_vm._v(" Detalhes ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }