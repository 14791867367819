export const actions = {
    login(context, login) {
        return new Promise((resolve) => {
            setTimeout(() => {
                context.commit('setLogin', login);
                resolve(true);                
            }, 1000);
        })
    },
    
    logout(context) {
        context.commit('resetMenu');
        context.commit('setLogout');
    },
    
    setSelectedMenu(context, menu) {
        context.commit('setSelectedMenu', menu);
    },
    
    setSelectedItemMenu(context, item) {
        context.commit('setSelectedItemMenu', item);
    },
    
    resetMenu(context) {
        context.commit('resetMenu');
    },

    setConfirmAnswer(context, answer) {
        context.commit('setConfirmAnswer', answer)
    },

    showModal(context, payload) {
        context.commit('showModal', payload)
    },
    showModalSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p class="text-primary"> Operação realizada com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalCadastroSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Curso FIC foi cadastro com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalCadastroOfertaSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Oferta cadastrada com sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalCadastroTurmaSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Turma cadastrada com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalAlteraçãoSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Curso FIC foi alterado com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalAlteraçãoOfertaSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Oferta de Curso foi alterada com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalAlteraçãoTurmaSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Turma alterada com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalAlteracaoCandidatoSucesso(context,msgSucesso){
        console.log('showModalAlteracaoCandidatoSucesso')
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Candidato alterado com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalExclusaoCandidatoSucesso(context,msgSucesso){
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Candidato excluido com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalExclusaoTurmaSucesso(context, msgSucesso) {
        let modalGeral = {
            actived: true,
            title: 'Aviso',
            body: '', // hmtl
            type: 1, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgSucesso) {
            modalGeral.body = `<p class="text-primary">${msgSucesso}</p>`
        } else {
            modalGeral.body = `<p v-if="isNew" class="text-xl text-primary"> Turma excluída com Sucesso!</p>`
        }
        context.commit('showModal', modalGeral)
    },
    showModalError(context, msgError) {
        let modalGeral = {
            actived: true,
            title: 'ERRO',
            body: '', // hmtl
            type: 2, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        let mensagemError = msgError
        if (msgError.response) {
            mensagemError = msgError.response.data.message
        }
        modalGeral.body = `<p class="text-red-600">${mensagemError}</p>`
        context.commit('showModal', modalGeral)
    },
    showModalConfirmar(context, msgConfirmar) {
        let modalGeral = {
            actived: true,
            title: 'Atenção',
            body: '', // hmtl
            type: 3, // 1 - Aviso | 2 - Error | 3 - Confirma
            confirm : {
                answer: null
            }
        }
        if (msgConfirmar) {
            modalGeral.body = `<p class="text-primary">${msgConfirmar}</p>`
        } else {
            modalGeral.body = `<p class="text-primary">Confirma operação?</p>`
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                context.commit('showModal', modalGeral)
                resolve(true);                
            }, 100);
        })
        
    },
    closeModal(context, payload) {
        context.commit('closeModal', payload)
    }
};