<template>
  <div
    v-if="this.showModal"
    class="main-modal w-full fixed inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster"
    style="background: rgba(0,0,0,.9);"
  >
    <div
      class="border shadow-lg modal-container bg-white md:max-w-full mx-auto max-h-full rounded z-50 overflow-y-auto"
    >
      <div class="modal-content pb-4 tex">
        <!--Title-->
        <div
          class="flex justify-between items-center rounded-sm"
        >
          <div class="container text-center text-sm uppercase p-1">
            <slot name="header">
              default header
            </slot>
          </div>
          <div class="modal-close cursor-pointer z-50" @click="modalClose">
            <svg
              class="fill-current mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              ></path>
            </svg>
          </div>
        </div>
        <!--Body-->
        <div class="container">
          <div class="w-full mt-2 mb-2 text-center text-lg">
            <slot name="body" v-if="tiposImg.includes(mimeType)">
              <img
                slot="body"
                :src="srcImage"
                class="lazy w-96"
                alt=""
              />
            </slot>
            <slot name="body" v-if="mimeType == 'pdf'">
              <!--   <embed :src="srcImage" height="600" width="600" /> -->
              <object
                height="600"
                width="600"
                type="application/pdf"
                :data="srcImage"
                ><embed type="application/pdf" :src="srcImage"
              /></object>
            </slot>
          </div>
        </div>
        <div class="container text-right" v-if="tiposImg.includes(mimeType)">
          <slot name="footer">
            <button
              title="Fazer o download do arquivo"
              type="submit"
              class="inline-flex bg-principal cursor-pointer hover:bg-blue-900 text-white text-sm font-semibold py-2 px-2 rounded-lg mx-1 mr-4"
              @click="donwloadAnexo"
            >
              <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
                <path
                  fill="currentColor"
                  d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                ></path>
              </svg> Salvar Imagem
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ModalShowImage",
  data() {
    return {
      tiposImg: ["png", "jpeg", "jpg"],
    };
  },
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    srcImage: {
      type: String,
    },
    mimeType: {
      type: String,
    },
    dadosImagem: {
       type: String
    }
  },
  computed: {
    ...mapGetters("adm", ["getModalGeral"]),
  },
  methods: {
    onCancel() {
      this.$emit("onCancel");
    },
    onClickedConfirm() {
      this.$emit("onConfirm");
    },

    async modalClose() {
      this.$emit("onClose");
    },

    donwloadAnexo() {
      const contentType = "jpeg";
      const b64Data = this.dadosImagem;
      const fileName = "entrega_cartao.jpeg";
      const blob = this.b64toBlob(b64Data, contentType);

      var fileURL = URL.createObjectURL(blob);
      var titulo = fileName;
      var downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download = titulo;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

  },
};
</script>
