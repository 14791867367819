import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/*webpackChunkName: "consulta" */ "../views/Principal.vue"),
  },
  {
    path: "/consultarcidadao",
    name: "ConsultarCidadao",
    component: () =>
      import(
        /*webpackChunkName: "consulta" */ "../views/consulta/ConsultaCidadao.vue"
      ),
  },
  {
    path: "/emissaocautela",
    name: "EmissaoCautela",
    component: () =>
      import(
        /*webpackChunkName: "emissao" */ "../views/cautela/EmissaoCautela"
      ),
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: () =>
      import(
        /*webpackChunkName: "relatorio" */ "../views/relatorio/RelatorioPlanilha.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
