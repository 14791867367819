import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

const state = {
    login: JSON.parse(window.localStorage.getItem('login')) || null,
    selectedMenu: JSON.parse(window.localStorage.getItem('selectedMenu')) || null,
    selectedItemMenu: JSON.parse(window.localStorage.getItem('selectedItemMenu')) || null,
    modalGeral: {
        actived: false,
        title: '',
        body: '', // hmtl
        type: null, // 1 - Aviso | 2 - Error | 3 - Confirma
        confirm : {
            answer: null
        }
    },
    loading: false,
};

const namespaced = true;

export const adm = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};