import axios from 'axios'
import store from './store'


const url = process.env.VUE_APP_BASE_URL_API

const instance = axios.create({
    baseURL: url,
    timeout: 60000
})

let instanciaKeycloak = undefined;
instance.interceptors.request.use(config => {
    let meuToken = store.getters["auth/getToken"];
    instanciaKeycloak = store.getters["auth/getKeycloak"];


    try {

        if (meuToken && (config.url.indexOf('auth/login') === -1)) {
            return instanciaKeycloak.updateToken(30).then(() => {
                instance.defaults.headers.common['Authorization'] = 'bearer ' + meuToken
                config.headers.Authorization = 'bearer ' + meuToken
                return Promise.resolve(config);
            }).catch(() => {
                instanciaKeycloak.logout();
            });
        }

        return config

    } catch (error) {
        console.log(error)
        window.console.log(error);
    }

})

instance.interceptors.response.use(
    config => config,
    (error) => {
        // console.log('Interceptor Response...')
        if (error.response.status === 408 || error.code === 'ECONNABORTED') {
            // console.log(`A timeout happend on url ${error.config.url}`)
            // const message = 'Tempo excedido para obter os dados! Tente novamente.'
            // store.dispatch('showSnackbarInfo', message)
        }
        if (error.response.status === 401) { // Usuario nao logado
            store.dispatch('adm/logout')
            instanciaKeycloak.logout();
        }
        return Promise.reject(error);
    },
);

export default instance
