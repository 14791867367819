import Vue from 'vue';
import Vuex from 'vuex';
import {auth} from './modules/auth';
import {adm} from './modules/adm';
import {consultaCidadao} from './modules/consultaCidadao';
import {emissaoCautela} from './modules/emissaoCautela';
import { relatorioPlanilha } from './modules/relatorioPlanilha';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        adm,
        consultaCidadao,
        emissaoCautela,
        relatorioPlanilha
    },
    state: {},
    mutations: {},
    actions: {},
    getters: {}

})

export default store
