<template>
  <div class="hidden md:block lg:block xl:block w-48 bg-principal text-white overflow-auto">
    <div class="flex flex-col justify-center">
      <div class="flex-1 text-center">
     
    <img src="@/assets/img/header-540.jpg" class="p-0" />
      </div>
      <div class="flex-1">
        <Menu/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Menu from './Menu'
export default {
  name: 'MenuPrincipal',
  components: {
    Menu
  },
  
  computed: {
    ...mapGetters('adm',['getSelectedMenu', "setSelectedItemMenu"]),
   
  }
}
</script>
