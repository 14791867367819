import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from "./getters";

const state = {
};

const namespaced = true;

export const relatorioPlanilha = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};
