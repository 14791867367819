var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "bg-white md:p-2 py-4 border-b" }, [
    _c("div", { staticClass: "flex-1" }, [
      _c(
        "div",
        {
          staticClass: "flex flex-col md:flex-row lg:space-x-2 justify-between"
        },
        [
          _c("div", { staticClass: "flex-shrink" }, [
            _c("span", { staticClass: "hidden md:flex" }, [
              _c("span", { staticClass: "font-bold text-principal px-2" }, [
                _vm._v("Auxílio Estadual ")
              ]),
              _c("strong", [_vm._v(" | ")]),
              _c("span", { staticClass: "text-gray-700 px-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.getSelectedItemMenu ? _vm.getSelectedItemMenu.item : ""
                  )
                )
              ])
            ]),
            _vm._m(0)
          ]),
          _c("div", { staticClass: "hidden md:flex" }, [
            _c("div", { staticClass: "flex flex-row justify-end" }, [
              _vm._v(" Usuário: " + _vm._s(_vm.getUserName) + " ")
            ])
          ]),
          _c("div", { staticClass: "hidden md:flex" }, [
            _c(
              "button",
              {
                staticClass: "mr-4 bg-gray-200 rounded px-4 py-1",
                on: { click: _vm.onClickedSair }
              },
              [
                _c("span", { staticClass: "icon text-principal" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "h-6 w-6 inline",
                      attrs: {
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "currentColor"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d:
                            "M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        }
                      })
                    ]
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "\n              title\n              inline\n              text-principal\n              font-semibold\n              tracking-wider\n              mx-1\n            "
                  },
                  [_vm._v("Sair")]
                )
              ]
            )
          ]),
          _c(
            "div",
            {
              staticClass:
                "\n          flex md:hidden\n          items-end\n          justify-end\n          h-full\n          p-4\n          bg-principal\n          text-white\n        "
            },
            [_c("DropdownMenu")],
            1
          ),
          _c("div", { staticClass: "flex md:hidden" }, [
            _c("div", { staticClass: "flex flex-row justify-end p-2 pt-3" }, [
              _vm._v(" Usuário: " + _vm._s(_vm.getUserName) + " ")
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "md:hidden" }, [
      _c("picture", [
        _c("source", {
          attrs: {
            media: "(max-width:540px)",
            srcset: require("@/assets/img/header-540.jpg")
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/img/header-auxilio-992.jpg"),
            alt: "",
            srcset: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }