<template>
  <footer class="bottom-0 mt-2 w-full bg-white p-2">
    <div
      class="flex flex-col lg:flex-row lg:space-x-2 justify-end items-end"
    >
     
        <a href="https://www.prodam.am.gov.br/">
          <img
            alt="Logo PRODAM"
            class="h-10 w-20 invert-white m-auto mr-4"
            src="https://apstatic.prodam.am.gov.br/images/prodam/logo-prodam-horizontal.svg"
          />
        </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPrincipal",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
