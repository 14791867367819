var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.showModal
    ? _c(
        "div",
        {
          staticClass:
            "main-modal w-full fixed inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster",
          staticStyle: { background: "rgba(0,0,0,.9)" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "border shadow-lg modal-container bg-white md:max-w-full mx-auto max-h-full rounded z-50 overflow-y-auto"
            },
            [
              _c("div", { staticClass: "modal-content pb-4 tex" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between items-center rounded-sm"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "container text-center text-sm uppercase p-1"
                      },
                      [
                        _vm._t("header", function() {
                          return [_vm._v(" default header ")]
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "modal-close cursor-pointer z-50",
                        on: { click: _vm.modalClose }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "fill-current mr-2",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "18",
                              height: "18",
                              viewBox: "0 0 18 18"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    { staticClass: "w-full mt-2 mb-2 text-center text-lg" },
                    [
                      _vm.tiposImg.includes(_vm.mimeType)
                        ? _vm._t("body", function() {
                            return [
                              _c("img", {
                                staticClass: "lazy w-96",
                                attrs: {
                                  slot: "body",
                                  src: _vm.srcImage,
                                  alt: ""
                                },
                                slot: "body"
                              })
                            ]
                          })
                        : _vm._e(),
                      _vm.mimeType == "pdf"
                        ? _vm._t("body", function() {
                            return [
                              _c(
                                "object",
                                {
                                  attrs: {
                                    height: "600",
                                    width: "600",
                                    type: "application/pdf",
                                    data: _vm.srcImage
                                  }
                                },
                                [
                                  _c("embed", {
                                    attrs: {
                                      type: "application/pdf",
                                      src: _vm.srcImage
                                    }
                                  })
                                ]
                              )
                            ]
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _vm.tiposImg.includes(_vm.mimeType)
                  ? _c(
                      "div",
                      { staticClass: "container text-right" },
                      [
                        _vm._t("footer", function() {
                          return [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "inline-flex bg-principal cursor-pointer hover:bg-blue-900 text-white text-sm font-semibold py-2 px-2 rounded-lg mx-1 mr-4",
                                attrs: {
                                  title: "Fazer o download do arquivo",
                                  type: "submit"
                                },
                                on: { click: _vm.donwloadAnexo }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px"
                                    },
                                    attrs: { viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "currentColor",
                                        d:
                                          "M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" Salvar Imagem ")
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }