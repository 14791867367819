var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-12 bg-white" }, [
    _c("div", { staticClass: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" }, [
      _c(
        "h2",
        { staticClass: "text-md font-extrabold tracking-wide uppercase my-2" },
        [_vm._v(" Emissão de Cautela ")]
      ),
      _c("div", { staticClass: "lg:text-center justify-center flex" }, [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col md:flex-row w-full md:w-4/5 lg:w-4/5 xl:w-2/3"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cpf,
                  expression: "cpf"
                }
              ],
              staticClass: "p-2 block w-full border border-gray-200",
              class:
                _vm.showErrorCpf && _vm.dirtyCpf ? "border border-red-500" : "",
              attrs: {
                type: "text",
                name: "cpf",
                placeholder: "Digite CPF",
                id: "cpf",
                required: ""
              },
              domProps: { value: _vm.cpf },
              on: {
                blur: function($event) {
                  _vm.dirtyCpf = true
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.cpf = $event.target.value
                }
              }
            }),
            _vm.showErrorCpf && _vm.dirtyCpf
              ? _c(
                  "p",
                  {
                    staticClass:
                      "text-red-500 text-xs italic text-center block md:hidden"
                  },
                  [_vm._v(" Campo Obrigatório. ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass:
                  "\n            bg-green-600\n            py-2\n            px-8\n            shadow-sm\n            text-sm\n            leading-4\n            font-medium\n            text-white\n            mt-2\n            md:mt-0\n            min-w-max\n          ",
                attrs: { type: "button" },
                on: { click: _vm.metodoEmitirCautela }
              },
              [_vm._v(" Emitir Cautela ")]
            )
          ]
        )
      ]),
      _vm.showErrorCpf && _vm.dirtyCpf
        ? _c(
            "p",
            {
              staticClass:
                "text-red-500 text-xs italic text-center hidden md:block"
            },
            [_vm._v(" Campo Obrigatório. ")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }