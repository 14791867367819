import axios from "./../../../axios-auth";

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    async getRelatorioRecebimento({}, request) {
        try {
            let endpoint = `relatorio/relRecebimento/cpf/${request}`;
            const response = await axios.get(endpoint, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/pdf',
                    'Accept': 'application/pdf'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
};
