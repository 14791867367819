// Importações
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './tailwind.css'
import Keycloak from "keycloak-js";


Vue.config.productionTip = false;


const initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required'
};

let keycloak = Keycloak(initOptions);
keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
    if (!auth) {
        console.log("Não autenticado")
        window.location.reload();
    } else {
        console.log("Authenticado");
        new Vue({
            router,
            store,
            el: '#app',
            render: h => h(App, {props: {keycloak: keycloak}})
        })
        store.dispatch("auth/setToken", keycloak.token);
        store.dispatch('auth/setUser', keycloak.tokenParsed.name);
        store.dispatch('auth/setKeycloak', keycloak)
        if (process.env.NODE_ENV === "master") {
            store.dispatch('auth/setUser', keycloak.tokenParsed.preferred_username);
        }
    }

//Token Refresh
    setInterval(() => {
        keycloak.updateToken(900).then((refreshed) => {
            console.log(refreshed)
            if (refreshed) {
                console.info('Token refreshed' + refreshed);
            } else {
                console.info('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch(() => {
            console.error('Falha ao atualizar o token');
        });
    }, 6000)

}).catch(() => {
    console.log("Authenticated Failed");
});
