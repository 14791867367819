<template>
  <div class="w-full">
    <span class="flex items-end justify-end text-white">
      <button
        @click="isOpen = !isOpen"
        class="
          ml-2
          rounded-full
          focus:outline-none focus:shadow-solid
        "
      >
        <div class="w-6 h-6 text-white">
          <svg
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </div>
      </button>
    </span>
    <button
      v-if="isOpen"
      @click="isOpen = false"
      tabindex="-1"
      class="z-10 fixed inset-0 h-full w-full cursor-default"
    ></button>
    <div
      v-if="isOpen"
      class="z-10 absolute right-0 mt-2 w-full bg-principal shadow-xl"
    >
      <div class="w-full flex flex-col" @click="onClickedMenu">
        <Menu />
        <span class="border-t py-4 text-left px-4" @click="onClickedSair">
          Sair</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Menu from "./Menu";

const actions = mapActions("auth", ["logout"]);

export default {
  name: "DropdownMenu",

  data() {
    return {
      isOpen: false,
    };
  },

  created() {
    const handleEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false;
      }
    };

    document.addEventListener("keydown", handleEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape);
    });
  },

  components: {
    Menu,
  },

  methods: {
    ...actions,

    onClose() {
      this.isOpen = false;
      this.$emit("onClose");
    },

    onClickedMenu() {
      this.onClose();
    },
    async onClickedSair() {
      localStorage.clear();
      window.location.href =
        `${process.env.VUE_APP_KEYCLOAK_URL}` +
        "/realms/auxilio/protocol/openid-connect/logout?redirect_uri=" +
        `${process.env.VUE_APP_FRONTEND_BASE}`;
    },
  },
};
</script>
