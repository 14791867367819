<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-md font-extrabold tracking-wide uppercase my-2">
          Consulta Cidadão
        </h2>
      <div class="lg:text-center justify-center flex">
        
        <div class="flex flex-col md:flex-row w-full md:space-x-4">
          <input
            type="text"
            name="cpfNome"
            id="cpfNome"
            placeholder="Digite CPF ou Nome"
            class="p-2 block w-full md:w-2/5 border border-gray-200"
            v-model="filtro"
            @input="filtrarConsulta()"
          />
            <div class="w-full md:w-2/5">
              <div class="relative w-full">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-200
                    text-gray-700
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  :class="isCPF ? 'bg-gray-100 cursor-not-allowed' : ''"
                  id="grid-state"
                  :disabled="isCPF"
                
                >
                <option>Todos</option>
                  <option
                  v-for="(item, index) in municipios"
                  :key="index"
                  :value="item"
                  >{{ item.municipio }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                ></div>
              </div>
            </div>
          <button
            type="button"
            class="
              bg-green-600
              px-8
              shadow-sm
              text-sm
              leading-4
              font-medium
              text-white
              mt-2
              md:mt-0
              py-3
            "
            @click="consultaBeneficiario"
          >
            Consultar
          </button>
        </div>
      </div>
    </div>
    <div
      class="pt-6 pb-2 mx-8"
      v-if="listaBeneficiario && listaBeneficiario.length > 0 && !showDetalhes"
    >
      <span class="block">Resultado da Consulta:</span>
      <span class="flex justify-start px-4"
        >Foram encontrados {{ pageable.total }} com os termos</span
      >
    </div>
    <div
      id="resultado"
      class="min-h-screen"
      v-if="listaBeneficiario && listaBeneficiario.length > 0"
    >
      <div class="mx-8 px-2 py-4 bg-white" v-if="!showDetalhes">
        <div class="relative py-4 pb-8">
          <table class="border-collapse w-full">
            <thead class="md:block hidden absolute sm:relative bg-white">
              <tr class="border border-gray-200 flex">
                <th
                  class="text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
                >
                  CPF
                </th>
                <th
                  class="text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
                >
                  Nome Beneficiário
                </th>
                <th
                  class="text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
                >
                  Data Nascimento
                </th>
                <th
                  class="text-left text-gray-700 capitalize px-4 py-2 md:w-1/5"
                >
                  Município
                </th>
                <th
                  class="
                    text-center text-gray-700
                    capitalize
                    px-4
                    py-2
                    md:w-1/5
                  "
                >
                  Detalhes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in listaBeneficiario"
                :key="item.id"
                class="
                  bg-white
                  shadow-lg
                  sm:shadow-none
                  mb-6
                  md:mb-0
                  flex flex-row flex-wrap
                  hover:bg-gray-100
                  sm:flex-no-wrap
                  border-b border-l-2 border-r-2
                  hover:border-gray-600
                "
              >
                <td
                  class="
                    pl-4
                    py-8
                    pb-2
                    md:pb-0
                    text-left
                    relative
                    w-2/4
                    md:w-1/5 md:px-4
                    border-t border-l-0
                    md:pt-4
                    border-gray-300
                  "
                >
                  <span
                    class="
                      font-bold
                      text-xs text-gray-700
                      uppercase
                      md:hidden
                      absolute
                      top-0
                      inset-x-0
                      p-1
                      bg-gray-200
                      py-2
                      pl-2
                    "
                  >
                    CPF
                  </span>
                  {{ item.cpf }}
                </td>
                <td
                  class="
                    pl-4
                    pt-8
                    pb-2
                    md:pb-0
                    text-left text-gray-800
                    relative
                    w-2/4
                    md:w-1/5 md:pt-4
                    border-t
                    md:border-l md:border-r
                    border-l-0
                    sm:border-r-0
                    border-gray-300
                  "
                >
                  <span
                    class="
                      font-bold
                      text-xs text-gray-700
                      uppercase
                      md:hidden
                      absolute
                      top-0
                      inset-x-0
                      p-1
                      bg-gray-200
                      py-2
                      pl-2
                    "
                  >
                    Nome Beneficiário
                  </span>
                  {{ item.nome }}
                </td>
                <td
                  class="
                    pl-4
                    sm:pl-0
                    pt-8
                    pb-2
                    md:pb-0 md:px-4
                    text-left text-gray-800
                    md:pt-4
                    relative
                    w-2/4
                    md:w-1/5
                    border-t
                    md:border-r
                    border-l-0 border-gray-300
                  "
                >
                  <span
                    class="
                      font-bold
                      text-xs text-gray-700
                      uppercase
                      md:hidden
                      absolute
                      top-0
                      inset-x-0
                      p-1
                      bg-gray-200
                      py-2
                      pl-2
                    "
                  >
                    Data Nascimento
                  </span>
                  {{ item.dataNascimento }}
                </td>
                <td
                  class="
                    pl-4
                    pt-8
                    pb-2
                    md:pb-0 md:px-4
                    text-left text-gray-800
                    md:pt-4
                    relative
                    w-2/4
                    md:w-1/5
                    border-t
                    md:border-r
                    border-l-0 border-r-0 border-gray-300
                  "
                >
                  <span
                    class="
                      font-bold
                      text-xs text-gray-700
                      uppercase
                      md:hidden
                      absolute
                      top-0
                      inset-x-0
                      p-1
                      bg-gray-200
                      py-2
                      pl-2
                    "
                  >
                    Município
                  </span>
                  {{ item.municipio }}
                </td>
                <td
                  class="
                    pl-4
                    sm:pl-0
                    pt-8
                    pb-2
                    md:pb-0
                    sm:pr-4
                    text-center
                    md:px-4 md:text-center md:pt-2
                    text-gray-800
                    relative
                    w-full
                    md:w-1/5
                    border-t border-l
                    sm:border-l-0
                    border-gray-300
                  "
                >
                  <span
                    class="
                      font-bold
                      text-xs text-gray-700
                      uppercase
                      md:hidden
                      absolute
                      top-0
                      inset-x-0
                      p-1
                      bg-gray-200
                      py-2
                      pl-2
                    "
                  >
                    Detalhes
                  </span>
                  <button
                    @click="exibirDetalhes(item)"
                    class="px-8 bg-blue-500 md:px-4 rounded mt-1"
                  >
                    <svg
                      class="text-gray-100"
                      style="width: 32px; height: 32px"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-pagination
          class="w-full flex'"
          :total-pages="pageable.totalPages"
          :total="pageable.total"
          :per-page="pageable.perPage"
          :current-page="pageable.currentPage"
          :has-more-pages="pageable.hasMorePages"
          @pagechanged="showMore"
        >
        </app-pagination>
      </div>
      <div  v-if="showDetalhes && beneficiario">
        <detalhes-cidadao 
          :cidadao="beneficiario"
          @onCloseDetalhes="showDetalhes = false"
          ></detalhes-cidadao>
      </div>
    </div>
    <div class="py-8" v-if="listaBeneficiario && listaBeneficiario.length == 0">
      Não foram encontrados dados com os termos.
    </div>

  </div>
</template>

<script>
import AppPagination from "@/components/common/Pagination";
import DetalhesCidadao from "./DetalhesCidadao.vue"

export default {
  data() {
    return {
      filtro: "",
      listaBeneficiario: null,
      pageable: {
        page: 1,
        totalPages: 0,
        total: 0,
        perPage: 10,
        currentPage: 0,
        hasMorePages: true,
      },
      currentPage: 0,
      showDetalhes: false,
      beneficiario: null,
      municipios: []
    };
  },
  components: {
    AppPagination,
    DetalhesCidadao
  },
  created() {
    
  },
  mounted() {
    this.getMunicipios();
  },
  computed: {
    showAlert() {
      return this.alerta.show;
    },

    isCPF() {
       let charsInit = this.filtro.charAt(0) + "";
       return !isNaN(parseInt(charsInit));
    }
  },
  methods: {
    async consultaBeneficiario() {
      try {
        await this.$store.commit("adm/setLoading", true);
        this.showDetalhes = false;
        let payload = null;
        if (this.isCPF) {
          payload = {
            cpfBeneficiario: this.filtro,
            nomeBeneficiario: "",
          };
        } else {
          payload = {
            nomeBeneficiario: this.filtro,
            cpfBeneficiario: "",
          };
        }
        let paramsPage = {
          pageSize: this.pageable.perPage,
          pageNumber: this.pageable.currentPage,
        };
        const response = await this.$store.dispatch(
          "consultaCidadao/getConsultaBeneficiarioService",
          { payload, paramsPage }
        );
        this.listaBeneficiario = response.content;
        
        await this.setPaginacao(response);
        
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

     async getMunicipios() {
      try {
        await this.$store.commit("adm/setLoading", true);

        const response = await this.$store.dispatch(
          "consultaCidadao/getMunicipioService"
        );
        this.municipios = response;
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

    async findBeneficiarioByCpf(dados) {
      try {
        await this.$store.commit("adm/setLoading", true);
        let payload = {
          cpfBeneficiario: dados.cpf,
          idBeneficio: dados.idBeneficio,
        };

        const response = await this.$store.dispatch(
          "consultaCidadao/findBeneficiarioByCpfService",
          payload
        );
        this.beneficiario = response;
        
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

    async exibirDetalhes(beneficiario) {
      this.beneficiario = beneficiario;
      this.showDetalhes = true;
    },

    async setPaginacao(pageable) {
      
      this.pageable.totalPages = pageable.totalPages;
      this.pageable.total = pageable.totalElements;
     
      this.pageable.page = pageable.number;
      this.pageable.perPage = pageable.size;
      
    },

    showMore(page) {
      
      this.pageable.page = page;
      this.pageable.currentPage = page;
      this.consultaBeneficiario();
    },

    async onCloseModalDetalhes() {
      this.showDetalhes = false;
    },

    filtrarConsulta() {
      this.pageable.currentPage = 0;
    }
  },
};
</script>

<style scoped></style>
