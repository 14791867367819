<template>
  <div class="min-h-screen principal">
    <MenuPrincipal class="aside"/>
    <div class="content">
      <HeaderPrincipal/>
      <!-- <NotificacaoGeral /> -->
      <div class="w-full overflow-y-auto">
        <router-view  class="fundo-body"/>
      </div>
       <FooterPrincipal/>
    </div>
  </div>
</template>

<script>
import MenuPrincipal from './MenuPrincipal'

import HeaderPrincipal from './HeaderPrincipal'
import FooterPrincipal from './FooterPrincipal'

export default {
  name: 'LayoutPrincipal',
  components: {
    MenuPrincipal,
    HeaderPrincipal,
    FooterPrincipal
  },
  
  computed: {
  //  ...mapGetters('adm',['getSelectedMenu'])
  }
}

</script>

<style>
.principal {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: "aside content";
}
.aside {
  grid-area: aside;
}
.content {
  grid-area: content;
  /* background-color: burlywood; */
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@media screen and (max-width: 760px){
/* @media screen and (max-width: 600px) { */
  .principal {
    grid-template-columns: 0px 1fr;
    grid-template-areas: "aside content";
  }
  .aside {
    display: none;
    visibility: hidden;
  }
  .content {
    grid-area: content;
  }
}

</style>
