export const mutations = {
    setLogin(state, login) {
        state.login = login
        window.localStorage.setItem('login', JSON.stringify(login))
    },
    setLogout(state) {
        state.login = null
        window.localStorage.removeItem('login')
    },
    setSelectedMenu(state, menu) {
        state.selectedMenu = menu
        window.localStorage.setItem('selectedMenu',JSON.stringify(menu))
    },
    setSelectedItemMenu(state, item) {
        state.selectedItemMenu = item
        window.localStorage.setItem('selectedItemMenu',JSON.stringify(item))
    },
    resetMenu(state) {
        state.selectedMenu = null
        state.selectedItemMenu = null
        window.localStorage.removeItem('selectedMenu')
        window.localStorage.removeItem('selectedItemMenu')
    },
    showModal(state, payload) {
        state.modalGeral.actived = true,
            state.modalGeral.title = payload.title
        state.modalGeral.body      = payload.body 
        state.modalGeral.type      = payload.type 
        state.modalGeral.confirm.answer = payload.confirm.answer
    },
    closeModal(state, answer) {
        state.modalGeral.actived   = false,
        state.modalGeral.title     = ''
        state.modalGeral.body      = ''
        state.modalGeral.type      = null
        state.modalGeral.confirm.answer = answer
    },
    setConfirmAnswer(state, answer) {
        state.modalGeral.confirm.answer = answer
    },
    setLoading(state, status) {
        state.loading = status
    },
};