import axios from "./../../../axios-auth";

export const actions = {
  async getConsultaBeneficiarioService({ commit }, { payload, paramsPage }) {
    try {
      let endpoint = null;
      if (payload.cpfBeneficiario) {
        endpoint = `beneficiario/from-result?cpfBeneficiario=${payload.cpfBeneficiario}&pageNumber=${paramsPage.pageNumber}&pageSize=${paramsPage.pageSize}`;
      } else {
        endpoint = `beneficiario/from-result?nomeBeneficiario=${payload.nomeBeneficiario}&pageNumber=${paramsPage.pageNumber}&pageSize=${paramsPage.pageSize}`;
      }

      const response = await axios.get(endpoint);
      commit("setBeneficiario", null);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async findBeneficiarioByCpfService({ commit }, payload) {
    try {
     let endpoint = `beneficiario/from-result/cpf/${payload.cpfBeneficiario}/beneficio/${payload.idBeneficio}`;
      const response = await axios.get(endpoint);
      commit("setBeneficiario", response);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async findEntregaCartaoService({ commit }, payload) {
    try {
      let endpoint = `entregaCartao/cpf/${payload.cpfBeneficiario}/beneficio/${payload.idBeneficio}`;
      const response = await axios.get(endpoint);
      commit("setEntrega", response);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async downloadImage({ commit }, payload) {
    try {
      let endpoint = `entregaCartao/cpf/${payload.cpf}/beneficio/${payload.idBeneficio}/anexo/${payload.idEntregaCartaoAnexo}`;
      await axios
        .get(endpoint, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          Buffer.from(response.data, "binary").toString("base64");
          commit(
            "setImage",
            Buffer.from(response.data, "binary").toString("base64")
          );
         // return Buffer.from(response.data, "binary").toString("base64");
        });
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getMunicipioService({}) {
    try {
      let endpoint = `municipio-calha/get-todos-ord-calha`;

      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
