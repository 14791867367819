import {actions} from './actions';
import {mutations} from './mutations';
import {getters} from "./getters";

const state = {
    beneficiario: null
};

const namespaced = true;

export const emissaoCautela = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};
