var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-center items-center" }, [
    _c(
      "ul",
      {
        staticClass:
          "w-full flex flex-wrap justify-center md:content-end pagination bg-white text-gray-700 py-2 shadow-sm rounded"
      },
      [
        _vm.showFirstLast
          ? _c("li", { staticClass: " pagination-item" }, [
              _c(
                "button",
                {
                  staticClass:
                    "block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full",
                  class: {
                    "cursor-not-allowed text-gray-500": _vm.isInFirstPage
                  },
                  attrs: {
                    type: "button",
                    disabled: _vm.isInFirstPage,
                    href: "#",
                    role: "button",
                    rel: "prev"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClickFirstPage.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { width: "24px", height: "24px" },
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d:
                            "M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _c("li", { staticClass: "pagination-item" }, [
          _c(
            "button",
            {
              staticClass:
                "block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full",
              class: { "cursor-not-allowed text-gray-500": _vm.isInFirstPage },
              attrs: {
                type: "button",
                disabled: _vm.isInFirstPage,
                "aria-label": "Go to previous page"
              },
              on: { click: _vm.onClickPreviousPage }
            },
            [
              _c(
                "svg",
                {
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { viewBox: "0 0 24 24" }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._l(_vm.pages, function(page) {
          return _c("li", { key: page.name, staticClass: "pagination-item" }, [
            _vm.isPageActive(page.name)
              ? _c(
                  "span",
                  {
                    staticClass:
                      "block shadow-md text-xs text-gray-100 font-bold bg-principal px-4 pt-2 py-1 h-full rounded mr-1"
                  },
                  [_vm._v(_vm._s(page.name))]
                )
              : page.name == "hasMorePage"
              ? _c(
                  "a",
                  {
                    staticClass:
                      "block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 py-1 h-full",
                    attrs: { href: "#", role: "button" },
                    on: { click: _vm.onClickNextPage }
                  },
                  [_vm._v(" ... ")]
                )
              : page.name == "hasLessPage"
              ? _c(
                  "a",
                  {
                    staticClass:
                      "block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 py-1 h-full",
                    attrs: { href: "#", role: "button" },
                    on: { click: _vm.onClickPreviousPage }
                  },
                  [_vm._v(" ... ")]
                )
              : _c(
                  "a",
                  {
                    staticClass:
                      "block text-xs hover:text-white hover:bg-gray-500 text-gray-700 rounded mr-1 border border-gray-light px-4 pt-2 py-1 h-full ",
                    attrs: { href: "#", role: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClickPage(page.name - 1)
                      }
                    }
                  },
                  [_vm._v(_vm._s(page.name))]
                )
          ])
        }),
        _c("li", { staticClass: "pagination-item" }, [
          _c(
            "button",
            {
              staticClass:
                "block text-xs hover:text-white hover:bg-gray-500 rounded mr-1 border border-gray-light px-3 py-1 h-full",
              class: { "cursor-not-allowed text-gray-500": _vm.isInLastPage },
              attrs: {
                type: "button",
                disabled: _vm.isInLastPage,
                "aria-label": "Go to next page"
              },
              on: { click: _vm.onClickNextPage }
            },
            [
              _c(
                "svg",
                {
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { viewBox: "0 0 24 24" }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm.showFirstLast
          ? _c("li", { staticClass: "pagination-item" }, [
              _c(
                "button",
                {
                  staticClass:
                    "block text-xs hover:text-white hover:bg-gray-500 roundeds mr-1 border border-gray-light px-3 py-1 h-full",
                  class: {
                    "cursor-not-allowed text-gray-500": _vm.isInLastPage
                  },
                  attrs: {
                    type: "button",
                    disabled: _vm.isInLastPage,
                    "aria-label": "Go to next page"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClickLastPage.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { width: "24px", height: "24px" },
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d:
                            "M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }