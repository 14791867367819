<template>
  <nav class="mt-2">
    <div class="py-2" v-if="onItems">
      <div class="" v-for="(item, index) in onItems" :key="index">
        <a
          class="cursor-pointer tracking-wider text-sm font-medium py-3"
          @click="onClickedItemMenu(item)"
          :class="classItemMenu(item)"
        >
          <span>{{ item.item }}</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import modulos from "@/mock/modulos";

export default {
  name: "Menu",
  async created() {
     await this.$store.dispatch('adm/setSelectedMenu', modulos)
     await this.$store.dispatch('adm/setSelectedItemMenu', 
     {
       item: "Consulta Cidadão",
       namePath: "ConsultarCidadao"
     });

      await this.$router.push({name: "ConsultarCidadao"})
  },

  computed: {
    ...mapGetters("adm", ["getSelectedMenu", "getSelectedItemMenu"]),
    onTitle() {
      return this.getSelectedMenu.title;
    },
    onItems() {
      return this.getSelectedMenu.items;
    },
     onMenu() {
      return this.getSelectedMenu;
    },
  },

  watch: {
    async onMenu(newValue, oldValue) {
      if (!newValue) {
        await this.$store.dispatch('adm/setSelectedMenu', oldValue)
      }
    },
  },

  methods: {
    classItemMenu(itemMenu) {
      if (this.getSelectedItemMenu) {
        return this.getSelectedItemMenu.item == itemMenu.item
          ? "flex justify-between items-center px-2 py-2  hover:selected-menu selected-menu"
          : "flex justify-between items-center px-2 py-2  hover:bg-gray-500";
      } else {
        return "flex justify-between items-center px-3 py-2  hover:bg-gray-400";
      }
    },
    classTextItemMenu(itemMenu) {
      if (this.getSelectedItemMenu) {
        return this.getSelectedItemMenu.item == itemMenu.item
          ? "text-center text-white"
          : "text-center text-white";
      } else {
        return "text-sm text-white";
      }
    },
    async onClickedItemMenu(item) {
      try {
        await this.$store.dispatch("adm/setSelectedItemMenu", item);
        await this.$router.push({name: item.namePath})
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
