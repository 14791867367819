const modulos = 
  {
    imageMDI: "fas fa-file-signature",
    title: "Auxílio Admin",
    namePath: "Home",
    items: [
      {
        item: "Consulta Cidadão",
        namePath: "ConsultarCidadao",
      },
      {
        item: "Emissão de Cautela",
        namePath: "EmissaoCautela",
      },
    {
        item: "Relatórios em Planilha",
        namePath: "Relatorios",
      },
    ],
  };

export default modulos;
