var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "bottom-0 mt-2 w-full bg-white p-2" }, [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col lg:flex-row lg:space-x-2 justify-end items-end"
        },
        [
          _c("a", { attrs: { href: "https://www.prodam.am.gov.br/" } }, [
            _c("img", {
              staticClass: "h-10 w-20 invert-white m-auto mr-4",
              attrs: {
                alt: "Logo PRODAM",
                src:
                  "https://apstatic.prodam.am.gov.br/images/prodam/logo-prodam-horizontal.svg"
              }
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }