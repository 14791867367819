<template>
  <div class="bg-gray-200 h-full">
<!--     <div class="container">
      <div class="flex flex-row justify-around">
        <div class="">
          <h1 class="flex-1 text-center main-title modules xs:text-4xl">{{ nomeProjeto }}</h1>
          <p class="flex-1 text-center text-dark  subtitle"> {{ nomeDaAplicacao }}</p>
        </div>
      </div>
    </div>
    <div class="mx-32 mb-16">
      <div class="flex flex-row flex-wrap justify-center items-center ">
        <div class="mx-2 my-4" v-for="card in modules" :key="card.title">
          <CardModules
              :title="card.name"
              :namePath="card.menu.namePath"
              :menu="card.menu"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
//import CardModules from '@/components/tools/CardModules'
//import modulos from "../mock/modulos";

export default {
  name: 'principal',
  components: {
   // CardModules
  },
  data() {
    return {
    //  modules: modulos,
      nomeProjeto:"Projeto Rede Governo",
      nomeDaAplicacao: "Sistema Administrativo Rede Governo"
    }
  },
  created() {
    this.$store.dispatch('adm/resetMenu')
  },
}
</script>

<style scoped>
html, body {
  width: 100%;
  height: 100%;
}
</style>
