<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-md font-extrabold tracking-wide uppercase my-2">
        Relatórios em Planilha
      </h2>
      <div class="lg:text-center">
        <form class="w-full">
          <div class="flex flex-wrap my-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  text-left
                "
                for="grid-first-name"
              >
                Calha
              </label>
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-200
                    text-gray-700
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-state"
                  v-model="calhaSelecionada"
                  @change="changeCalha"
                >
                 <option value="">Todos</option>
                  <option
                    v-for="(item, index) in calhas"
                    :key="index"
                    :value="item.calha"
                  >
                    {{ item.calha }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                ></div>
              </div>
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  text-left
                "
                for="grid-first-name"
              >
                Município
              </label>
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-200
                    text-gray-700
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-state"
                  v-model="municipioSelecionado"
                  @change="changeMunicipio"
                >
                  <option value="">Todos</option>
                  <option
                    v-for="(item, index) in municipios"
                    :key="index"
                    :value="item.municipio"
                  >
                    {{ item.municipio }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                ></div>
              </div>
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  text-left
                "
                for="grid-last-name"
              >
                Zona
              </label>
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-200
                    text-gray-700
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  :class="!isManaus ? 'bg-gray-100 cursor-not-allowed' : ''"
                  id="grid-state"
                  v-model="zonaSelecionada"
                  @change="changeZona"
                  :disabled="!isManaus"
                >
                  <option value="">Todos</option>
                  <option v-for="(item) in zonas" 
                          :value="item"
                          :key="item">
                    {{item}}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                ></div>
              </div>
            </div>
            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  text-left
                "
                for="grid-last-name"
              >
                Local de Entrega
              </label>
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-200
                    text-gray-700
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  :class="!isManaus ? 'bg-gray-100 cursor-not-allowed' : ''"
                  id="grid-state"
                  v-model="localSelecionado"
                  @change="changeZona"
                  :disabled="!isManaus"
                >
                  <option value="">Todos</option>
                  <option v-for="(item) in locais" 
                          :value="item"
                          :key="item">
                    {{item}}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                ></div>
              </div>
            </div>
            <div class="w-full my-6 md:mb-0 text-left px-6">
              <label class="md:w-2/3 block text-gray-500 font-bold">
                <input class="mr-2 leading-tight" type="checkbox" />
                <span class="text-sm"> Cartões Entregues </span>
              </label>
            </div>
            <div class="w-full my-6 md:mb-0">
              <button
                type="button"
                class="
                  bg-green-600
                  px-8
                  shadow-sm
                  text-sm
                  leading-4
                  font-medium
                  text-white
                  mt-2
                  md:mt-
                  w-full
                  md:max-w-sm md:w-40
                  max-w-screen-sm
                  py-3
                "
                @click="gerarCSV"
              >
                Gerar Planilha
              </button>
            </div>
          </div>
        </form>
      </div>
      <p
        class="text-red-500 text-xs italic text-center hidden md:block"
        v-if="showErrorCpf && dirtyCpf"
      >
        Campo Obrigatório.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmissaoCautela",
  data() {
    return {
      cpf: undefined,
      dirtyCpf: false,
      calhas: null,
      municipios: null,
      zonas: null,
      locais: null,
      calhaSelecionada: "",
      municipioSelecionado: "",
      zonaSelecionada: "",
      localSelecionado: "",
    };
  },
  mounted() {
    this.getCalhas();
  },
  computed: {
    showErrorCpf() {
      return this.cpf == undefined || this.cpf == null || this.cpf == "";
    },
    isManaus() {
      return this.municipioSelecionado == "MANAUS";
    },
  },
  methods: {
    async getCalhas() {
      try {
        await this.$store.commit("adm/setLoading", true);

        const response = await this.$store.dispatch(
          "relatorioPlanilha/getCalhaService"
        );
        await this.filterRemoveDuplicates(response, "calha").then((c) => {
          this.calhas = c;
          this.municipioSelecionado=null;
          this.zonaSelecionada=null;
        });
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },
    async getMunicipiosByCalha() {
      try {
        await this.$store.commit("adm/setLoading", true);

        const response = await this.$store.dispatch(
          "relatorioPlanilha/getMunicipioByCalhaService",
          this.calhaSelecionada
        );
        this.municipios = response;
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },
    async getZonasDeManaus() {
      try {
        await this.$store.commit("adm/setLoading", true);

        const response = await this.$store.dispatch(
          "relatorioPlanilha/getZonaService"
        );
        this.zonas = response;
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },
    async getLocaisEntregaManaus() {
      try {
        await this.$store.commit("adm/setLoading", true);

        const response = await this.$store.dispatch(
          "relatorioPlanilha/getLocalEntregaService"
        );
        this.locais = response;
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },        

    async gerarCSV() {
      try {
        await this.$store.commit("adm/setLoading", true);
        let payload = {
          nomeMunicipio : this.municipioSelecionado,
          calha : this.calhaSelecionada
        };

        
        const response = await this.$store.dispatch(
          "relatorioPlanilha/gerarRelatorioCSV",
          payload
        );

        

        var blob = new Blob([response.data], {
          type: "text/csv",
        });
        var fileURL = window.URL.createObjectURL(blob);
        var title = "relatorio_planilha.csv";
        var downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = title;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.log("Erro ao buscar CSV", error);
      } finally {
        this.$store.commit("adm/setLoading", false);
      }
    },

    changeCalha() {
      this.getMunicipiosByCalha();
    },

    changeMunicipio() {
      console.log(this.municipioSelecionado)
      if(this.municipioSelecionado == 'MANAUS'){
        this.getZonasDeManaus();
      }
      else{
        this.zonas = null;
        this.locais = null;
      }      
    },
    changeZona() {
        this.getLocaisEntregaManaus()
    },    

    //Remove dados duplicados de uma lista
    async filterRemoveDuplicates(lista, param) {
      var resArr = [];
      lista.filter(function (item) {
        var i = resArr.findIndex((x) => x[param] == item[param]);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return resArr;
    },
  },
};
</script>

<style scoped></style>
