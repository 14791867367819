export const getters = {
    getToken: state => {
        return state.token
    },
    isAuthenticated: state => state.token !== null,
    getUserName: state => {
        return state.user
    },
    getKeycloak: state => {
        return state.instanciaKeycloak;
    }
};
