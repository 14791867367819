export const mutations = {

    setToken(state, token) {
        state.token = token;
    }
    ,
    setUserName(state, userName) {
        state.user = userName
    },
    setKeycloak(state, instanciaKeycloak) {
        state.instanciaKeycloak = instanciaKeycloak
    }
}
