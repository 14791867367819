export const getters = {
    login: state => {
        return state.login
    },
    isAuthenticated: state => {
        return state.login
    },
    getSelectedMenu: state => {
        return state.selectedMenu 
    },
    getSelectedItemMenu: state => {
        return state.selectedItemMenu
    },
    showModalGeral: state => {
        return state.modalGeral.actived

    },
    getModalGeral: state => {
        return state.modalGeral || 'teste'
    },
    getModalConfirmAnswer: state => {
        return state.modalGeral.confirm.answer
    },
    loading: state => {
        return state.loading
    },
};