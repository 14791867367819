<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-md font-extrabold tracking-wide uppercase my-2">
        Emissão de Cautela
      </h2>
      <div class="lg:text-center justify-center flex">
        <div
          class="flex flex-col md:flex-row w-full md:w-4/5 lg:w-4/5 xl:w-2/3"
        >
          <input
            type="text"
            name="cpf"
            placeholder="Digite CPF"
            class="p-2 block w-full border border-gray-200"
            :class="showErrorCpf && dirtyCpf ? 'border border-red-500' : ''"
            v-model="cpf"
            @blur="dirtyCpf = true"
            id="cpf"
            required
          />
          <p
            class="text-red-500 text-xs italic text-center block md:hidden"
            v-if="showErrorCpf && dirtyCpf"
          >
            Campo Obrigatório.
          </p>
          <button
            type="button"
            class="
              bg-green-600
              py-2
              px-8
              shadow-sm
              text-sm
              leading-4
              font-medium
              text-white
              mt-2
              md:mt-0
              min-w-max
            "
            @click="metodoEmitirCautela"
          >
            Emitir Cautela
          </button>
        </div>
      </div>
      <p
        class="text-red-500 text-xs italic text-center hidden md:block"
        v-if="showErrorCpf && dirtyCpf"
      >
        Campo Obrigatório.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmissaoCautela",
  data() {
    return {
      cpf: undefined,
      dirtyCpf: false,
    };
  },
  computed: {
    showErrorCpf() {
      return this.cpf == undefined || this.cpf == null || this.cpf == "";
    },
  },
  methods: {
    async metodoEmitirCautela() {
      try {
        this.dirtyCpf = true;
        await this.$store.commit("adm/setLoading", true);
        if (this.cpf) {
          const data = await this.$store.dispatch(
            "emissaoCautela/getRelatorioRecebimento",
            this.cpf
          );
          var blob = new Blob([data], {
            type: "application/pdf",
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
      } catch (e) {
        console.log(e)
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },
  },
};
</script>

<style scoped></style>
