var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "mt-2" }, [
    _vm.onItems
      ? _c(
          "div",
          { staticClass: "py-2" },
          _vm._l(_vm.onItems, function(item, index) {
            return _c("div", { key: index }, [
              _c(
                "a",
                {
                  staticClass:
                    "cursor-pointer tracking-wider text-sm font-medium py-3",
                  class: _vm.classItemMenu(item),
                  on: {
                    click: function($event) {
                      return _vm.onClickedItemMenu(item)
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(item.item))])]
              )
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }