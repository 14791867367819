export const actions = {
    setToken({commit}, state) {
        commit("setToken", state)
    },
    logout(state) {
        state.commit('setToken', null);
        window.localStorage.removeItem('authToken');
    },
    setUser({commit}, state) {
        commit("setUserName", state)

    },
    setKeycloak({commit}, state) {
        commit("setKeycloak", state)

    }
}
