var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster"
        },
        [
          _c(
            "div",
            {
              staticClass:
                " modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg  z-50 overflow-y-auto"
            },
            [
              _c("div", { staticClass: "modal-content py-6 text-left px-1" }, [
                _c("div", { staticClass: "flex flex-col items-center" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _c("div", { staticClass: "lds-spinner" }, [
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div")
                    ])
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }