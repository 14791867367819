var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "hidden md:block lg:block xl:block w-48 bg-principal text-white overflow-auto"
    },
    [
      _c("div", { staticClass: "flex flex-col justify-center" }, [
        _vm._m(0),
        _c("div", { staticClass: "flex-1" }, [_c("Menu")], 1)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-1 text-center" }, [
      _c("img", {
        staticClass: "p-0",
        attrs: { src: require("@/assets/img/header-540.jpg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }