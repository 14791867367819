import axios from "./../../../axios-auth";

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async getCalhaService({}) {
    try {
      let endpoint = `municipio-calha/get-todos-ord-calha`;

      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getMunicipioByCalhaService({}, calha) {
    try {
      let endpoint = `municipio-calha/get-municipios-by-calha/${calha}`;

      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getZonaService({}) {
    try {
      let endpoint = `municipio-bairro-zona/get-zonas`;

      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getLocalEntregaService({}) {
    try {
      let endpoint = `entregaCartao/get-por-municipio/Manaus`;

      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

// eslint-disable-next-line no-empty-pattern
  async gerarRelatorioCSV({  }) {
    try {
      let endpoint = ``;

      const response = await axios.get(endpoint, {
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
