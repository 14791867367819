<template>
  <div class="m-6" v-if="cidadao && beneficiario">
    <span class="block pb-2 pt-4 uppercase">Detalhes do Cidadão:</span>
    <div id="resultado" class="min-h-screen bg-white">
      <div>
        <div
          class="
            flex flex-col
            sm:grid sm:grid-cols-3
            px-5
            text-left
            border-gray-200
            p-4
          "
        >
          <div class="border">
            <dt class="font-medium text-gray-900 p-2">CPF:</dt>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2">
              {{ beneficiario.cpf }}
            </dd>
          </div>
          <div class="border">
            <dt class="font-medium text-gray-900 p-2">Nome Beneficiário:</dt>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2">
              {{ beneficiario.nome }}
            </dd>
          </div>

          <div class="border">
            <dt class="font-medium text-gray-900 p-2">Município:</dt>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2">
              {{ beneficiario.municipio }}
            </dd>
          </div>
          <div class="border col-span-2">
            <dt class="font-medium text-gray-900 p-2">
              Local de Entrega do Cartão:
            </dt>
            <dd
              class="text-sm text-gray-500 bg-gray-200 p-2"
              v-if="beneficiario.possuiLocalEntregaCartao"
            >
              {{ beneficiario.localEntregaCartao.local }}
            </dd>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2" v-else>
              Local de entrega não definido
            </dd>
          </div>
          <div class="border">
            <dt class="font-medium text-gray-900 p-2">Status de Entrega:</dt>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2">
              <span
                class="bg-green-200 p-2 text-green-700"
                v-if="entregaCartao"
                >Entregue</span
              >
              <span class="bg-yellow-100 p-2 text-yellow-700" v-else
                >Não Entregue</span
              >
            </dd>
          </div>
          <div class="border">
            <dt class="font-medium text-gray-900 p-2">Benefício:</dt>
            <dd class="text-sm text-gray-500 bg-gray-200 p-2">
              {{ beneficiario.descricaoBeneficio }}
            </dd>
          </div>
        </div>
        <div class="px-5" v-if="entregaCartao">
          <span class="block py-4 uppercase">Detalhes da Entrega:</span>
          <div
            class="flex flex-col sm:grid sm:grid-cols-3 border-gray-300 border"
            v-if="cidadao && beneficiario"
          >
            <div class="border">
              <dt
                class="
                  font-medium
                  text-gray-900
                  bg-gray-200
                  border-b border-gray-300
                  p-2
                "
              >
                Cartão Entregue por:
              </dt>
              <dd class="text-sm text-gray-500 bg-gray-200 p-2">{{ entregaCartao.cpfEntregador }}</dd>
            </div>
            <div class="border">
              <dt
                class="
                  font-medium
                  text-gray-900
                  bg-gray-200
                  border-b border-gray-300
                  p-2
                "
              >
                Nome Entregador:
              </dt>
              <dd class="text-sm text-gray-500 bg-gray-200 p-2">
                {{ entregaCartao.nomeEntregador }}
              </dd>
            </div>

            <div class="border">
              <dt
                class="
                  font-medium
                  text-gray-900
                  bg-gray-200
                  border-b border-gray-300
                  p-2
                "
              >
                Data Entrega:
              </dt>
              <dd class="text-sm text-gray-500 bg-gray-200 p-2">
                 {{ entregaCartao.dataHoraEntregaFormatada }}
              </dd>
            </div>
            <p class="col-span-3 text-left bg-gray-200 p-3 border-t border-gray-300">Clique para visualizar a(s) imagem(ens) da Entrega do Cartão.</p>
          </div>
          
          <div
            class="
              px-5
              grid grid-cols-1
              gap-y-10 gap-x-6
              sm:grid-cols-2
              lg:grid-cols-4
              xl:gap-x-8
              bg-gray-200
              border-t border-gray-300
              py-2
            "
          >
         
            <div
              v-for="item in entregaCartao.urls"
              :key="item.id"
              class="group relative"
            >
              <div
                class="
                  flex-shrink-0
                  border border-gray-200
                  rounded-md
                  overflow-hidden
                "
              >
                <a class="cursor-pointer text-blue-500 font-semibold" @click="onClickedView(item)">
                  
                  <svg class="inline" style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z" />
                  </svg>
                  <span class="inline"> Entrega Cartão </span>
                 <!--  <img
                    :src="item.url"
                    class="
                      w-48
                      object-center object-cover
                      lg:w-full lg:h-full
                    "
                  /> -->
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 py-8 text-center">
          <button
            type="button"
            class="
              bg-blue-600
              py-2
              px-8
              shadow-sm
              text-sm
              leading-4
              font-medium
              text-white
              mt-2
              md:mt-0
            "
            @click="voltarConsulta()"
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
    <modal-show-image
      :showModal="showModalViewImage"
      :srcImage="infoImage"
      :mimeType="'jpg'"
      :dadosImagem="this.imagemCartao"
      v-on:onClose="onCloseViewImage()"
    >
      <h2 slot="header">Entrega Cartão</h2>
    </modal-show-image>
  </div>
</template>

<script>
import ModalShowImage from "@/components/modals/ModalShowImage";
import { mapGetters } from "vuex";
export default {
  name: "DetalhesCidadao",
  props: {
    cidadao: {
      type: Object,
    },
  },
  data() {
    return {
      filtro: "",
      listaBeneficiario: null,
      pageable: {
        page: 1,
        totalPages: 0,
        total: 0,
        perPage: 10,
        currentPage: 0,
        hasMorePages: true,
      },
      currentPage: 0,
      showModalDetalhes: false,
      beneficiario: null,
      entregaCartao: null,
      infoImage: null,
      showModalViewImage: false,
    };
  },
  components: {
    ModalShowImage,
  },
  async created() {
    await this.findBeneficiarioByCpf(this.cidadao);
  },
  mounted() {},
  computed: {
    ...mapGetters("consultaCidadao", ["imagemCartao"]),
    showAlert() {
      return this.alerta.show;
    },
  },
  methods: {
    async findBeneficiarioByCpf(dados) {
      try {
        await this.$store.commit("adm/setLoading", true);
        let payload = {
          cpfBeneficiario: dados.cpf,
          idBeneficio: dados.idBeneficio,
        };

        const response = await this.$store.dispatch(
          "consultaCidadao/findBeneficiarioByCpfService",
          payload
        );
        this.beneficiario = response;
        if (this.beneficiario.possuiLocalEntregaCartao) {
          await this.findEntregaCartao(this.beneficiario);
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

    async findEntregaCartao(dados) {
      try {
        await this.$store.commit("adm/setLoading", true);
        let payload = {
          cpfBeneficiario: dados.cpf,
          idBeneficio: dados.idBeneficio,
        };

        const response = await this.$store.dispatch(
          "consultaCidadao/findEntregaCartaoService",
          payload
        );
        this.entregaCartao = response;
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

    async onShowImage(anexo) {
      try {
        await this.$store.commit("adm/setLoading", true);
        let payload = {
          cpf: this.beneficiario.cpf,
          idBeneficio: this.beneficiario.idBeneficio,
          idEntregaCartaoAnexo: anexo.id,
        };
      
        await this.$store
          .dispatch("consultaCidadao/downloadImage", payload)
          .then(() => {
            // this.mimeTypePhoto = this.candidate.MIMETYPE;
            this.infoImage = "data:image/jpeg;base64, " + this.imagemCartao;
          });
      } catch (e) {
        console.log("e", e);
      } finally {
        await this.$store.commit("adm/setLoading", false);
      }
    },

    async onClickedView(anexo) {
      await this.onShowImage(anexo);
      this.showModalViewImage = true;
    },

    async exibirDetalhes(beneficiario) {
      await this.findBeneficiarioByCpf(beneficiario);
      this.showModalDetalhes = true;
    },

    voltarConsulta() {
      this.$emit("onCloseDetalhes", true);
    },

    async onCloseViewImage() {
      this.showModalViewImage = false;
    },
  },
};
</script>

<style scoped></style>
