var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c("span", { staticClass: "flex items-end justify-end text-white" }, [
      _c(
        "button",
        {
          staticClass:
            "\n        ml-2\n        rounded-full\n        focus:outline-none focus:shadow-solid\n      ",
          on: {
            click: function($event) {
              _vm.isOpen = !_vm.isOpen
            }
          }
        },
        [
          _c("div", { staticClass: "w-6 h-6 text-white" }, [
            _c(
              "svg",
              {
                attrs: {
                  fill: "none",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  stroke: "currentColor",
                  viewBox: "0 0 24 24"
                }
              },
              [_c("path", { attrs: { d: "M4 6h16M4 12h16M4 18h16" } })]
            )
          ])
        ]
      )
    ]),
    _vm.isOpen
      ? _c("button", {
          staticClass: "z-10 fixed inset-0 h-full w-full cursor-default",
          attrs: { tabindex: "-1" },
          on: {
            click: function($event) {
              _vm.isOpen = false
            }
          }
        })
      : _vm._e(),
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass:
              "z-10 absolute right-0 mt-2 w-full bg-principal shadow-xl"
          },
          [
            _c(
              "div",
              {
                staticClass: "w-full flex flex-col",
                on: { click: _vm.onClickedMenu }
              },
              [
                _c("Menu"),
                _c(
                  "span",
                  {
                    staticClass: "border-t py-4 text-left px-4",
                    on: { click: _vm.onClickedSair }
                  },
                  [_vm._v(" Sair")]
                )
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }