export const mutations = {
  setBeneficiario(state, beneficiario) {
        state.beneficiario = beneficiario;
  },
  setImage(state, imagemCartao) {
    state.imagemCartao = imagemCartao;
  },
   setEntrega(state, entrega) {
    state.entrega = entrega;
  }
};
