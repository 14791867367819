<template>
  <header class="bg-white md:p-2 py-4 border-b">
    <div class="flex-1">
      <div class="flex flex-col md:flex-row lg:space-x-2 justify-between">
        <div class="flex-shrink">
          <span class="hidden md:flex"
            ><span class="font-bold text-principal px-2">Auxílio Estadual </span
            ><strong> | </strong>
            <span class="text-gray-700 px-2">{{
              getSelectedItemMenu ? getSelectedItemMenu.item : ""
            }}</span>
          </span>
          <span class="md:hidden">
            <picture>
              <source
                media="(max-width:540px)"
                srcset="@/assets/img/header-540.jpg"
              />
              <img src="@/assets/img/header-auxilio-992.jpg" alt="" srcset="" />
            </picture>
          </span>
        </div>

        <div class="hidden md:flex">
          <div class="flex flex-row justify-end">
            Usuário: {{ getUserName }}
          </div>
        </div>

        <div class="hidden md:flex">
          <button
            class="mr-4 bg-gray-200 rounded px-4 py-1"
            @click="onClickedSair"
          >
            <span class="icon text-principal">
              <svg
                class="h-6 w-6 inline"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </span>
            <span
              class="
                title
                inline
                text-principal
                font-semibold
                tracking-wider
                mx-1
              "
              >Sair</span
            >
          </button>
        </div>
        <div
          class="
            flex md:hidden
            items-end
            justify-end
            h-full
            p-4
            bg-principal
            text-white
          "
        >
          <DropdownMenu />
        </div>
         <div class="flex md:hidden">
          <div class="flex flex-row justify-end p-2 pt-3">
            Usuário: {{ getUserName }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import DropdownMenu from "@/components/layout/DropdownMenu";

export default {
  name: "HeaderPrincipal",
  components: {
    DropdownMenu,
  },
  data() {
    return {
      isOpenLogin: false,
      user: "undefined",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("adm", ["getSelectedItemMenu"]),
    ...mapGetters("auth", ["isAuthenticated", "getUserName"]),
  },
  methods: {
    onClickedEntrar() {
      this.isOpenLogin = true;
    },
    async onClickedHome() {
      try {
        await this.$store.dispatch("adm/resetMenu");
        if (this.isAuthenticated) {
          if (this.$route.path != "/home") {
            this.$router.push({ name: "Home" });
          }
        } else {
          if (this.$route.path != "/") {
            this.$router.push({ name: "Home" });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onClickedSair() {
      localStorage.clear();

      window.location.href =
        `${process.env.VUE_APP_KEYCLOAK_URL}` +
        "/realms/auxilio/protocol/openid-connect/logout?redirect_uri=" +
        `${process.env.VUE_APP_FRONTEND_BASE}`;
    },
  },
};
</script>
